body {
  background-image: url('./m4.png');
  background-position: center;
  background-attachment: fixed;
}

@import '~fontsource-roboto/100.css';
@import '~fontsource-roboto/300.css';
@import '~fontsource-roboto/400.css';
@import '~fontsource-roboto/500.css';
@import '~fontsource-roboto/700.css';
@import '~fontsource-roboto/900.css';
